@use 'partials' as *;

.componentStyle {
  position: relative;
  display: block;
  width: size(80);
  height: size(80);
  margin: 0 auto;
}

.componentStyle div {
  position: absolute;
  border: size(4) solid var(--color-primary);
  border-radius: 50%;
  opacity: 1;
  animation: loading-anime 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.componentStyle div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loading-anime {
  0% {
    top: size(36);
    left: size(36);
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: size(36);
    left: size(36);
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: size(36);
    left: size(36);
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    width: size(72);
    height: size(72);
    opacity: 0;
  }
}
